'use client';
import { CCButton } from '../ui/button';
import { useRouter } from 'next/navigation';
import { FC, MouseEventHandler, ReactNode } from 'react';

interface ClientNavButtonProps {
  children: ReactNode; // 按钮内容，可以是字符串或 JSX 元素
  className?: string; // 可选的样式类名
  targetPath?: string; // 跳转路径，默认为 "/"
}

export function ClientCCNavBtn({
  children,
  className,
  targetPath = '/',
}: ClientNavButtonProps) {
  const router = useRouter();
  const handleClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    router.push(targetPath);
  };
  return (
    <CCButton className={className} onClick={handleClick}>
      {children}
    </CCButton>
  );
}
